import * as u from 'JS/utils';
if (window.FileReader) {
  u.domReady(() => {
    document.addEventListener('change', function(e) {
      if(e.target.className == 'image_upload') {
        const reader = new FileReader(), rFilter = /^(image\/bmp|image\/cis-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x-cmu-raster|image\/x-cmx|image\/x-icon|image\/x-portable-anymap|image\/x-portable-bitmap|image\/x-portable-graymap|image\/x-portable-pixmap|image\/x-rgb|image\/x-xbitmap|image\/x-xpixmap|image\/x-xwindowdump)$/i;
        const element = e.target
        if (element.files.length === 0) { return; }
        const file = element.files[0];
        if (!rFilter.test(file.type)) { return; }
        reader.readAsDataURL(file);

        reader.onload = function (oFREvent) {
          const preview = element.previousElementSibling;
          preview.src = oFREvent.target.result;
          preview.style.display = "block";
        };
      }
    })
  })
}
