import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    this.inputTargets.forEach(input => { this.check(input) })
  }

  selectAll(event) {
    event.preventDefault()
    this.inputTargets.forEach(input => {
      input.checked = true
      this.check(input)
    })
  }

  deselectAll(event) {
    event.preventDefault()
    this.inputTargets.forEach(input => {
      input.checked = false
      this.check(input)
    })
  }

  select(event) {
    this.check(event.currentTarget)
  }

  check(input) {
    const cardClass = this.data.get("card-class")
    const cardCheckedClass = this.data.get("card-checked-class")
    const thisCard = input.closest('label')
    thisCard.classList.toggle(cardCheckedClass, input.checked)
  }
}
