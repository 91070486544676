import * as u from 'JS/utils';



u.domReady(() => {
  const toggle = document.getElementById('toggle-menu')
  if(toggle) {
		toggle.addEventListener('click', e => {
			if (e.target.classList.contains('navigation-open')) {
				e.target.classList.remove('navigation-open');
				document.querySelector('body').classList.remove('no-scroll')
			} else if (e.target.classList.contains('hamburger')) {
				e.target.classList.add('navigation-open');
				document.querySelector('body').classList.add('no-scroll')
			}
		})
  }
});
