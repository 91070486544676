import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "formField", "links", "template" ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass")
  }

  addFields(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML("beforebegin", content)
  }

  removeFields(event) {
    event.preventDefault()

    const wrapper = event.target.closest("." + this.wrapperClass)

    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = "none"
    }
  }
}

