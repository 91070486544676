import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.min.css';

const swiperOptions = {
  slidesPerView: 1,
  centeredSlides: true,
  loop: true,
  pagination: {
    el: '.carousel__nav',
    type: 'bullets',
    bulletClass: 'carousel__nav-bullet',
    bulletActiveClass: 'carousel__nav-bullet--active',
  },
  navigation: {
    nextEl: '.swiper-button-right',
    prevEl: '.swiper-button-left',
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true
  },
  on: { // below is to fix bad width calculated for slides
    init() {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 200)
    },
  },

}

document.addEventListener("turbolinks:load", function() {
  const swiperContainer = document.getElementById('swiper')

  var mySwiper = new Swiper(swiperContainer, swiperOptions);
});