import { Controller } from "stimulus"

/**
 * Radio buttons to control an elements text content.
 *
 * On each radio input, add four data attributes
 * 1) data-to-toggle="*": a css selector of the element to change.
 * 2) data-content="*": the text content it will toggle.
 * 3) data-target="radio-toggle.input": to expose it to this class.
 * 4) data-action="radio-toggle#toggle": to expose it to this class.
 *
 */
export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    this.inputTargets.forEach(input => { this._check_input(input) })
  }

  toggle(event) {
    this._check_input(event.currentTarget)
  }

  _check_input(input) {
    if (input.checked) {
      document.querySelector(input.dataset.toToggle).innerText = input.dataset.content
    }
  }
}
